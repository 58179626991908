import PropTypes from "prop-types";
import React from "react";
import { graphql, StaticQuery } from 'gatsby'
import { ThemeContext } from "../layouts";
import Blog from "../components/Blog";
import Hero from "../components/Hero";
import Seo from "../components/Seo";
import _JSXStyle from "styled-jsx/style";

class IndexPage extends React.Component {
  separator = React.createRef();

  scrollToContent = e => {
    this.separator.current.scrollIntoView({ block: "start", behavior: "smooth" });
  };

  render() {
    return (
      <StaticQuery query={graphql`
          query IndexQuery {
            posts: allMarkdownRemark(
              filter: { fileAbsolutePath: { regex: "//posts/[0-9]+.*--/" } }
            ) {
              edges {
                node {
                  excerpt
                  timeToRead
                  fields {
                    slug
                    prefix
                  }
                  frontmatter {
                    title
                    category
                    author
                    cover {
                      children {
                        ... on ImageSharp {
                          fluid(maxWidth: 800, maxHeight: 360) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            site {
              siteMetadata {
                facebook {
                  appId
                }
              }
            }
            bgDesktop: imageSharp(fluid: { originalName: { regex: "/hero-background2/" } }) {
              resize(width: 1200, quality: 90, cropFocus: CENTER) {
                src
              }
            }
            bgTablet: imageSharp(fluid: { originalName: { regex: "/hero-background2/" } }) {
              resize(width: 800, height: 1100, quality: 90, cropFocus: CENTER) {
                src
              }
            }
            bgMobile: imageSharp(fluid: { originalName: { regex: "/hero-background2/" } }) {
              resize(width: 450, height: 850, quality: 90, cropFocus: CENTER) {
                src
              }
            }
          }
      `}
      render = { data => {
          const {
            posts: { edges: posts = [] },
            bgDesktop: {
              resize: { src: desktop }
            },
            bgTablet: {
              resize: { src: tablet }
            },
            bgMobile: {
              resize: { src: mobile }
            },
            site: {
              siteMetadata: { facebook }
            }
          } = data;

          const backgrounds = {
            desktop,
            tablet,
            mobile
          };

          return (
            <React.Fragment>
            <ThemeContext.Consumer>
              {theme => (
                <Hero scrollToContent={this.scrollToContent} backgrounds={backgrounds} theme={theme} />
              )}
            </ThemeContext.Consumer>
    
            <hr ref={this.separator} />
    
            <ThemeContext.Consumer>
              {theme => <Blog posts={posts} theme={theme} />}
            </ThemeContext.Consumer>
    
            <Seo facebook={facebook} />
    
            <style jsx>{`
              hr {
                margin: 0;
                border: 0;
              }
            `}</style>
          </React.Fragment>
          )
        }
      }
    />
    );
  }
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default IndexPage;

//hero-background
